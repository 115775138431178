import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'

//Components
import Layout from "../components/layout"
import Findus from "../components/findus"

const FindUsPage = () => (
  <Layout>
    <Findus/>
  </Layout>
)

export default FindUsPage
